//$debug: true;
$debug: false;

// $baseFont: 'Raleway', sans-serif;

$globalFont: 'Roboto Slab', serif;


$maxWidthInner: 1320px;
$maxWidthOuter: 1920px;

$headerSize: 80px;
$asideSize: 240px;


// colors

$color1: #324640;
$color3: #fff;


