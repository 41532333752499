@include media-breakpoint-up(lg) {
    nav {    
        ul {
            list-style: none;
            li {
                display: inline-block;
                margin: 0 1rem;
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

html {
    overflow-x: hidden;
}

body {
    max-width: 100vw;
    overflow-x: hidden;
    background-color: #fff;
    font-family: $globalFont;
}

img,
svg {
    @include max(100%,none);
}

a {
    color: #000;
}

p {
    margin-bottom: 0;
    font-size: rem-calc(16);
    font-weight: 300;
    @include media-breakpoint-down(sm) {
        font-size: rem-calc(15);
    }
}

p,a,span,h1,h2,h3,h4,h5 {
    font-family: $globalFont !important;
}

.link {
    font-size: rem-calc(30);
    &:hover {
        text-decoration: none;
        color: $color1;
    }
}

.h1 {
    // text-transform: uppercase;
    font-weight: 400;
    @include media-breakpoint-up(lg) {
        font-size: rem-calc(80);        
    }
}

.h2 {
    // text-transform: uppercase;
    font-weight: 400;
    @include media-breakpoint-up(lg) {
        font-size: rem-calc(60);
    }
}

.h3 {
    // text-transform: uppercase;
    font-weight: 500;
    @include media-breakpoint-up(lg) {
        font-size: rem-calc(50);
    }
}

.h4 {    
    font-weight: 500;
    @include media-breakpoint-up(lg) {
        font-size: rem-calc(35);
    }
}

h1,h2,h3,h4 {
    line-height: 120%;
    span {
        display: inline-block;
        -webkit-text-stroke: 2px #000;
        color: $color3;
    }
}

img {
    @include max(100%,none);
    @include size(auto,auto);
}

.shape {
    &.wave {
        transform: translate(-25%,0);
    }
}

.read-more {
    font-weight: bold;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    
    svg {
        margin-right: 1rem;
    }
}

.slick-dotted.slick-slider {
    margin-bottom: 5rem;
}

.slick-dots {
    bottom: -5rem;
    li {
        @include size(25px,25px);
        border-radius: 100%;
        background-color: #000;
        button {
            opacity: 0;            
        }
        &.slick-active {
            background-color: $color1;
        }
    }
}

.reviews-slider {
    margin-bottom: 5rem;
}

.has-line {
    
}

.pagination {
    text-align: center;
    margin-top: 5rem;
    a,span {
        background-color: $color1;
        color: #fff !important;
        text-decoration: none !important;
        border-radius: 100px;
        @include size(45px,45px);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: top;
        &.current {
            background-color: #fff;
            color: $color1 !important;
            border: 3px solid $color1;
        }
    }
}