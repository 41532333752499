.pre-header {
    background-color: $color1;
    color: #fff;
    padding: 4px;
}
nav {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
        .center-icon {
            transform: translate(-50%,-50%) scale(0.75);
        }
        .circle-link {
            width: 100%;
        }
    }
    a {
        color: #fff;
        display: block;
        text-align: center;
        margin: 1rem auto;
        font-weight: 300;
        font-size: 2rem;
        &:hover {
            color: #fff;
        }
    }
}
header {
    padding: 1rem 2rem;

    @include media-breakpoint-down(sm) {
        .logo-img {
            max-width: 75px;
            margin-left: 2rem;
            margin-top: 1rem;
        }
    }

    &.scrolled {

        top: 0;
        .logo-img {
            filter: invert(23%) sepia(22%) saturate(477%) hue-rotate(111deg) brightness(31%) contrast(87%); 
        }
        .menu.hamburger {
            .line {
                stroke: $color1;
            }
        } 
    }
    
  

    @include media-breakpoint-up(lg) {
        transition: all 250ms;
        @include fixed(2rem,auto,auto,50%,100);
        @include size(100%,auto);
        transform: translate(-50%,0);
        margin-bottom: 3rem;
        img {
            transition: all 300ms ease-in-out;
            max-height: none;
            height: auto;
        }
        nav {
            display: flex;
            align-items: center;
            ul {
                margin-bottom: 0;
            }
        }
       
        
        
    }
    @include media-breakpoint-down(md) {
        @include fixed(0,auto,auto,0,10);
        @include size(100%,auto);
        padding-left: 0;
        padding-right: 0;
        &.scrolled {
            mix-blend-mode: hard-light;
        }
        nav {
            @include fixed(0,auto,auto,0,7);
            @include size(75vw,100vh);
            @include max(350px,none);
            background: #fff;
            box-shadow: 0 0 20px rgba(0,0,0,0.3);
            padding: 2rem 1rem;
            border-left: 5px solid $color1;
            transition: all 400ms ease-in-out;
            transform: translate(-110%,0);
            &.show {
                transform: translate(0,0);
            }
            ul {
                list-style: none;
                 li {
                    display: block;                    
                    text-decoration: none !important;
                    margin-bottom: 0.8rem;
                    a {
                        color: #000;
                        font-weight: 700;
                    }
                 }
            }
            
        }
    }
    .burger-box {
        position: relative;
        .lang-select {
            @include absolute(auto,auto,0,50%,10);
            transform: translate(-50%,100%);
            width: 60px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            a {
                font-size: 1.25rem;
                font-weight: bolder;
                &.active {
                    color: $color1;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
        @include media-breakpoint-down(md) {
            &:before {
                // content: '';
                @include size(80px,120px);
                background-color: #fff;
                // border-radius: 100%;
                @include absolute(50%,auto,auto,50%,0);
                transform: translate(-50%,-35%);
            }
        }
    }
}

nav {
    @include fixed(0,auto,auto,0,50);
    @include size(100%,100%);
    background: $color1;
    transition: all 250ms ease-in-out;

    opacity: 0;
    transform: translate(0,-100%);

    .menu-items {
        @include absolute(50%,auto,auto,50%,5);
        transform: translate(-50%,-50%);
        @include size(80%,auto);
        @include max(600px,none);
    }

    &.show {
        opacity: 1;
        transform: translate(0,0);
    }

    @include media-breakpoint-down(md) {
        z-index: 7;
    }
}

.has-burger {
    @include media-breakpoint-down(md) {
        display: flex;
        justify-content: flex-end;
    }
}

.menu.hamburger {
    // @include absolute(50%,1rem,auto,auto,1000);
    // transform: translate(0,-50%);

    z-index: 9;

    // margin-left: 2.5rem;

    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;

    // position: relative;
    @include size(100px,80px);
    outline: none !important;        
  
  .line {
    fill: none;
    stroke: #fff;
    mix-blend-mode: multiply;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  &.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  &.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  &.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
}

body.has-mobile-nav {
    .menu.hamburger {
        .line {
            // stroke: $color1;
        }
    }    
    .logo-img {
        filter: invert(0);      
    }
    .menu.hamburger {
        .line {
            stroke: #fff;
        }
    } 
}

.scroll-trigger#nav {
    @include absolute(100vh,0,auto,auto,1);
}