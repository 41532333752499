@charset 'UTF-8';

// Vendors
@import
  'bootstrap/bootstrap',  
  'vendors/misc',
  'vendors/slick',
  'vendors/original';


// Common
@import
  'common/variables',
  'common/mixins',
  'common/helpers',
  'common/common',
  'common/sections',
  'common/header',
  'common/footer';

// Pages
@import  
  'pages/sections';