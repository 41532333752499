section {
    position: relative;
    @include max(1920px,none);
    margin:0 auto;

    &.hero {

        background-color: $color1;

        margin-bottom: 4rem;
       
        @include media-breakpoint-up(lg) {

            @include size(100%,100vh);

        }

        @media only screen and (min-width: 997px) {

            .mobile {
                display: none;
            }

            .bg-img {
                position: absolute;
                top: 0;
                right: 50%;
                z-index: 1;
                width: auto;
                height: 100vh;
            }

            .copy {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(0,-50%);
                width: 40%;
                color: #fff;                
            }

        }

        .logo-img {
            position: absolute;
            top: 4rem;
            right: 6rem;
            z-index: 1;            
        }

        

        .frame {
            @include absolute(auto,auto,auto,auto,2);
            background-color: #fff;
            @include size(1px,1px);            
            &.top {
                top: 2rem;
                left: 2rem;                
                width: calc(100vw - 5rem);                
            }
            &.right {
                top: 2rem;
                right: 2rem;                
                height: calc(100vh - 4rem);        
            }
            &.bottom {
                bottom: 2rem;
                left: 2rem;
                width: calc(100vw - 5rem);                
            }
            &.left {
                top: 2rem;
                left: 2rem;                
                height: calc(100vh - 4rem);     
            }
        }
      

        .link {
            display: flex;
            align-items: center;
            color: #fff;
            text-decoration: none !important;
        }

        .link:hover {
            color: #fff;
        }

        .link img {
            margin-right: 1rem;
        }

        .fb {
            position: absolute;
            bottom: 4rem;
            left: 50%;
            transform: translate(-50%,0);            
        }

        .fb:after {
            content: '';
            position: absolute;
            top: 2rem;
            left: 50%;
            width: 1px;
            height: 3rem;
            background: #fff;
        }

        @media only screen and (max-width: 996px) {

            body {
                position: relative;
            }

            .desktop {
                display: none;
            }

            .bg-img {
                position: relative;
                width: 100vw;
                height: auto;
            }

            .copy {
                position: relative;
                color: #fff;
                padding: 3rem;
                padding-top: 8rem;
            }

            .logo-img {
                position: absolute;
                top: 4rem;
                left: 50%;
                transform: translate(-50%,0);
            }

            .frame {
                position: absolute;
            }

            .fb {
                position: absolute;
                bottom: 4rem;
                left: 50%;
                transform: translate(-50%,0);            
            }

            .frame.top {
                top: 1rem;
                left: 1rem;
                width: calc(100vw - 2rem);
            }
            .frame.right {
                top: 1rem;
                right: 1rem;
                height: calc(100% - 2rem);            
            }
            .frame.bottom {
                bottom: 1rem;
                left: 1rem;
                width: calc(100vw - 2rem);
            }
            .frame.left {
                top: 1rem;
                left: 1rem;
                height: calc(100% - 2rem);            
            }

        }

    }

    &.info {
        article {
            background: $color1;
            color: #fff;
            padding: 4rem;
            z-index: 10;
            @include size(100%,auto);
            @include media-breakpoint-up(lg) {
                transform: translate(10rem,0);
            }
            @include media-breakpoint-down(md) {
                z-index: 1;
            }
            hr {
                background: #fff;
                box-shadow: none;
                display: block;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
        .has-img {
            z-index: -1;
            display: flex;
            align-items: center;
        }
        &.rev {
            article {
                @include media-breakpoint-up(lg) {
                    transform: translate(-10rem,0);
                }   
            }
        }

        img {
            opacity: 0;
            transform: translate(15%,0);
            transition: all 350ms ease-in-out;
        }
        article {
            opacity: 0;
            margin-left: -6rem;
            transition: all 350ms ease-in-out;
        }

        &.shown {
            img {
                opacity: 1;
                transform: translate(0,0);
            }
            article {
                opacity: 1;
                margin-left: 0;
            }
        }
    }

    &.items-showcase {
        margin-top: 10rem;
        margin-bottom: 10rem;
        @include media-breakpoint-down(md) {
            .d-flex {
                padding-top: 6rem;
            }
        }
        .slider-items {
            .slick-slide {
                text-align: center;
                img {
                    display: inline-block;
                    margin: 0 auto;                    
                }
                @include media-breakpoint-down(md) {
                    img {
                        max-width: 350px;
                    }
                }
                @include media-breakpoint-down(sm) {
                    img {
                        max-width: 250px;
                    }
                }
            }
        }
        .bg-shape {
            @include absolute(50%,0,auto,auto,-1);
            @include size(100%,auto);
            @include max(1200px,none);
            transform: translate(0,-50%);
        }
        hr {
            background: $color1;
            box-shadow: none;
        }

        &.rev {
            .bg-shape {
                left: 0;
                right: auto;
            }   
        }

        .slider-items {
            opacity: 0;
            transform: translate(15%,0);
            transition: all 350ms ease-in-out;
        }
        .h1,.description {
            opacity: 0;
            margin-left: -6rem;
            transition: all 350ms ease-in-out;
        }

        &.shown {
            .slider-items {
                opacity: 1;
                transform: translate(0,0);
            }
            .h1,.description {
                opacity: 1;
                margin-left: 0;
            }
        }
    }


    &.services {
        background-color: $color1;
        padding-top: 6rem;
        color: #fff;

        .icons {
            @include media-breakpoint-up(lg) {
                columns: 5;
            }
            margin-top: 3rem;
            .item {
                text-align: center;
                height: 200px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: center;
            }
        }



        .box {
            margin-top: 5rem;
            padding: 2rem;
            border: 2px solid #fff;
            border-top-left-radius: 2rem;
            border-top-right-radius: 2rem;
            .btn {
                transform: translate(0,100%);
                border: 1px solid #fff;
                border-radius: 100px;
                padding: 0.5rem 1rem;
                color: #fff;
                display: inline-block;
                background-color: $color1;
            }
        }
    }

    &.partners {
        margin-top: 10rem;
        margin-bottom: 10rem;

        .slick-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
        }
    }

    &.stats {
        margin-top: 10rem;
        margin-bottom: 10rem;
    }

    &.social-media {
        margin-top: 10rem;
        margin-bottom: 10rem;
    }

}

hr {
    height: 1px;
}

@include media-breakpoint-down(md) {
    .slick-slider {
        margin-bottom: 8rem;
    }
}


@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

@keyframes bounce-left {
    // from {transform: rotate(0deg);}
    // to {transform: rotate(360deg);}
}

